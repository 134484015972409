.Sidebar {
  width: 200px;
  height: 100vh;
  background-color: #868484;
  color: #efadad;
  padding: 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.Sidebar h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.Sidebar ul {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.Sidebar li {
  padding: 10px;
  border-bottom: 1px solid #444;
}

.Sidebar li a{
    text-decoration: none;
  }

  
.Sidebar li i {
  font-size: 18px;
  margin-right: 10px;
}

.Sidebar li:hover {
  background-color: #555;
  cursor: pointer;
}
