.ReferAndEarnTable {
 margin-top: 20px;
}


.Sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 15px;
}

.Sidebar h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.Sidebar ul {
  list-style-type: none;
  padding: 0;
}

.Sidebar li {
  margin: 10px 0;
  cursor: pointer;
}


.ReferAndEarnContent {
  flex-grow: 1;
  padding: 20px;
}

.ReferAndEarnContent h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.ReferAndEarnContent p {
  font-size: 16px;
}

.ReferralSection, .ReferralSteps, .RewardsSection {
  margin-top: 20px;
}

.ReferralLink {
  display: flex;
  align-items: center;
}

.ReferralLink input {
  width: 300px;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ReferralLink button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ReferralLink button:hover {
  background-color: #0056b3;
}

.ReferralSteps ol {
  padding-left: 20px;
}

.RewardsSection p {
  font-size: 16px;
  font-weight: bold;
}


.form-group {
  margin-bottom: 15px;
}
.form-group label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  text-align: left;
}
.form-group input {
  margin-left: 10px;
  width: 10%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.form-group input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.button-group .save-button {
  background-color: #4caf50;
  color: white;
}
.button-group .cancel-button {
  background-color: #f44336;
  color: white;
}