.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 85%;
  max-height: 80%; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

label input,
label textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label input[type="file"] {
  padding: 3px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

button[type="button"] {
  background-color: #f44336;
  color: white;
}
