.BannerAdminContainer {
  display: flex;
}
.BannerAdminContent {
  flex-grow: 1;
  padding: 20px;
}
.BannerGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.img_box{
  border: 4px solid green;
  padding: 4px;
  max-width: 20vw;
}
.BannerItem {
  position: relative;
  width: 100%;
  padding-top: 50%; 
  overflow: hidden;
  background: #f0f0f0;
  border: 1px solid #ccc;
}
.imgInput {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  cursor: pointer;
}
.txtInput{
  width: 80%;
}

button {
  background-color: #4CAF50; 
  border: none; 
  width: 98%;
  color: white; 
  padding: 15px 32px; 
  text-align: center; 
  text-decoration: none;
  display: inline-block; 
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; 
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

button:active {
  background-color: #3e8e41; 
  transform: scale(1);
  box-shadow: 0 5px #666; 
  transform: translateY(4px); 
}