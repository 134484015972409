.TitleTextBox {
  width: 100%;
  padding: 10px;
  font-size: 24px;
  margin-bottom: 20px;
}

.BodyTextBox {
  width: 100%;
  height: 200px;
  padding: 10px;
  font-size: 16px;
  resize: none;
}

.ReferAndEarnContainer {
  display: flex;
}

.ReferAndEarnContent {
  flex-grow: 1;
  padding: 20px;
}
